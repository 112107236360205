// src/components/research/components/runningImageString/RunningImageString.jsx
// This file uses ES6 modules (ESM)

import { h_700_16_100_32 } from "@/styles/fontStyles";
import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState, useCallback } from "react";
import { RunningImageHandler } from "./RunningImageHandler";

export const RunningImageString = ({ currentCompaniesList }) => {
  const [companies, setCompanies] = useState([]);

  const checkImageExists = async (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };

  const processItem = useCallback(async (item) => {
    if (!item.fileLogoSquare) return null;

    const imageUrl = `${process.env.NEXT_PUBLIC_IMAGES_URL}${item.fileLogoSquare}`;
    const isImageExists = await checkImageExists(imageUrl);

    return isImageExists ? item : null;
  }, []);

  useEffect(() => {
    let isCancelled = false;

    const fetchCompanies = async () => {
      const promises = currentCompaniesList?.map(processItem);
      const results = await Promise.all(promises);
      const validCompanies = results.filter((item) => item !== null).slice(0, 40);

      if (!isCancelled) {
        setCompanies(validCompanies);
      }
    };

    const timer = setTimeout(fetchCompanies, 10);

    return () => {
      clearTimeout(timer);
      isCancelled = true;
    };
  }, [currentCompaniesList, processItem]);

  return (
    <Flex
      flexDir="column"
      align="center"
      gap="24px"
      mb={{ base: "42px", md: "14px", lg: "120px" }}
    >
      <Text
        {...h_700_16_100_32}
        color="mainBlue"
        px="20px"
        textAlign="center"
      >
        Research early, fast growing startups like these with aVenture
      </Text>
      <RunningImageHandler data={companies.slice(0, 20)} />
      <RunningImageHandler runDir="left" data={companies.slice(21, 40)} />
    </Flex>
  );
};
