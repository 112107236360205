// src/utils/imgSelector.js
// This file uses ES6 modules (ESM)

/**
 * Selects the appropriate image URL based on the provided data and image type.
 *
 * @param {Object} data - The data object containing image URLs.
 * @param {string} imgType - The type of image to select.
 * @returns {string} The URL of the selected image.
 */
export const imgSelector = ({ data, imgType }) => {
  const baseUrl = `${process.env.NEXT_PUBLIC_IMAGES_URL}`;

  const {
    fileLogoSquare,
    fileLogoRectangle,
    fileLogo,
    personPicture,
    picture,
  } = data || {};

  const logo =
    fileLogoSquare || fileLogoRectangle || fileLogo || personPicture || picture;
  const checkLogo = logo?.split(".")?.[1] ? logo : [logo, "jpg"]?.join(".");
  const defaultImage =
    data?.personPicture || imgType === "person"
      ? "/images/no-avatar.svg"
      : "/images/no-company.svg";

  const imageUrl = `${baseUrl}${checkLogo}`;

  if (!logo) {
    return defaultImage;
  }

  return imageUrl;
};
