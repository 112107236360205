// src/components/research/components/companyResearch/index.jsx
// This file uses ES6 modules (ESM)

import BlueInfoBox from "@/components/ui/blueInfoBox/BlueInfoBox";
import { BlueTextBoxArrow } from "@/components/ui/box/BlueTextBoxArrow";
import { MainWrapper } from "@/components/ui/box/MainWrapper";
import SeeMoreBtn from "@/components/ui/buttons/SeeMoreBtn";
import SmallCard from "@/components/ui/SmallCard/SmallCard";
import { getUserIsVerify } from "@/store/auth/auth.selectors";
import { customTextSection } from "@/utils/cardCompanyResearch";
import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SwiperMobileCard from "../../../ui/SwiperMobileCard";

function CompanyResearch({ companiesList }) {
  const [companies, setCompanies] = useState([]);

  // Moved checkImageExists outside of useEffect to reduce nesting
  const checkImageExists = async (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };

  useEffect(() => {
    const loadCompanies = async () => {
      const newCompanies = [];
      if (companiesList) {
        for (const item of companiesList) {
          if (newCompanies.length >= 12) break;
          if (!item.fileLogoSquare) continue;
          const imageUrl = `${process.env.NEXT_PUBLIC_IMAGES_URL}${item.fileLogoSquare}`;
          const isImageExists = await checkImageExists(imageUrl);
          if (isImageExists) {
            newCompanies.push(item);
          }
        }
        setCompanies(newCompanies);
      }
    };

    loadCompanies();
  }, [companiesList]);

  const firstSixItems = companies.slice(0, 6);
  const firstTwelve = companies.slice(0, 12);
  const isLogIn = useSelector(getUserIsVerify());

  return (
    <MainWrapper customStyleOuter={{ px: { base: 0, lg: "40px" }, mb: "40px" }}>
      <Flex
        justifyContent={isLogIn ? "center" : "space-between"}
        flexDirection={{ base: "column", xl: "row" }}
        className="hide-scrollbar"
        alignItems={{ base: "center", xl: "center" }}
        gap={"20px"}
      >
        {!isLogIn ? (
          <BlueInfoBox
            customStyle={{
              pb: { base: "12px", md: "18px" },
              fontSize: { base: "2em", md: "3.375em" },
              lineHeight: { base: "130%", md: "normal" },
              color: "white",
            }}
            styleOfBox={{
              borderRadius: { base: "none", lg: "10px" },
              minW: { base: "100%", xl: "unset" },
              maxW: { base: "100%", xl: "calc(50% - 10px)" },
              maxH: "441px",
            }}
            titleText="World Class Venture Research"
            text="aVenture brings public market-quality research to private markets. Get free access for longer by creating an account today."
            buttonText="Get Free Research Account"
            px={{ base: "37px", md: "110px", lg: "65px" }}
            headingType={"h2"}
            styleOfAddition={{
              textAlign: { base: "center", md: "start", lg: "center" },
            }}
            btnData={{ href: "/sign-up", alt: "sign-up", handler: () => {} }}
          />
        ) : null}
        <Flex
          justify={{ base: "center", xl: "right" }}
          maxW={{ base: "100%", xl: isLogIn ? "100%" : "65%" }}
          minW={{
            base: "100%",
            md: isLogIn ? "100%" : "100%",
            lg: isLogIn ? "100%" : "100%",
            xl: isLogIn ? "100%" : "fit-content",
          }}
          px={{
            base: "unset",
            md: isLogIn ? "40px" : "40px",
            lg: "unset",
          }}
        >
          <Flex
            flexDir={"column"}
            w={{ base: "100%", md: isLogIn ? "100%" : "100%" }}
          >
            <Flex mt={{ base: "40px", xl: 0 }}>
              <BlueTextBoxArrow title="Company Research" />
            </Flex>
            <Box
              w={isLogIn ? "100%" : "100%"}
              display={{ base: "block", md: "none" }}
            >
              <SwiperMobileCard cardsInfo={firstSixItems} />
            </Box>
            <Flex
              justifyContent="center"
              alignItems={"center"}
              mt="32px"
              display={{ base: "none", md: "flex" }}
            >
              {isLogIn ? (
                <Grid
                  templateColumns={{
                    base: "1fr",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(3, 1fr)",
                    xl: "repeat(4, 1fr)",
                  }}
                  gap={4}
                  w={isLogIn ? "fit-content" : "unset"}
                >
                  {firstTwelve.map((item, index) => (
                    <GridItem key={`${item.id}-${index}`}>
                      <SmallCard item={item} textSection={customTextSection} />
                    </GridItem>
                  ))}
                </Grid>
              ) : (
                <Grid
                  templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                  gap={4}
                >
                  {firstSixItems.map((item, index) => (
                    <GridItem key={`${item.id}-${index}`}>
                      <SmallCard item={item} textSection={customTextSection} />
                    </GridItem>
                  ))}
                </Grid>
              )}
            </Flex>
            <Flex display={isLogIn ? "none" : "flex"} justify={"center"}>
              <Link href={"/companies"}>
                <SeeMoreBtn btnText={"See More"} customStyles={{ mt: "7px" }} />
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex display={isLogIn ? "flex" : "none"} justify={"center"}>
        <Link href={"/companies"}>
          <SeeMoreBtn btnText={"See More"} customStyles={{ mt: "7px" }} />
        </Link>
      </Flex>
    </MainWrapper>
  );
}

export default CompanyResearch;
