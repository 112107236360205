// env_config.js

/**
 * Environment Configuration
 *
 * This file defines environment-specific settings for the application.
 * It should ONLY contain code that can be safely run in both server and client environments,
 * and MUST NOT use any Node.js-specific APIs (e.g., 'fs', 'path', 'process.cwd()', 'dotenv').
 *
 * **Guidelines:**
 * - Use environment variables for all configurations.
 * - Prefix environment variables with 'NEXT_PUBLIC_' if they need to be accessible on the client side.
 * - Avoid including sensitive information directly in this file.
 * - Do not import Node.js modules that are not supported in the Edge Runtime or the browser.
 */

// Use environment variables from process.env
const currentEnv = process.env.NEXT_ENVIRONMENT || 'production';

// Define the configuration object
const ENVIRONMENT_CONFIGS = {
  misc: {
    atlasAppId: process.env.NEXT_PUBLIC_ATLAS_APP_ID,
    googleTagManagerId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  },
  // Firebase environment for authentication, keys, and SSO
  firebase: {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
    repoName: process.env.REPO_NAME || 'front-end',
    basicAuthActivated: process.env.BASIC_AUTH_ACTIVATED === 'true',
    accountSettingsInvestor: 'user',
    linkedInAuth: {
      clientId: process.env.NEXT_PUBLIC_LINKEDIN_OAUTH_CLIENT_ID,
      clientSecret: process.env.LINKEDIN_OAUTH_SECRET,
      scope: 'r_liteprofile r_emailaddress',
      authorizationUrl: 'https://www.linkedin.com/oauth/v2/authorization',
      tokenUrl: 'https://www.linkedin.com/oauth/v2/accessToken',
      profileUrl: 'https://api.linkedin.com/v2/me',
      profileParams:
        '(id,localizedFirstName,localizedLastName,profilePicture(displayImage~:playableStreams))',
      redirectPath: '/api/auth/callback/linkedin',
    },
    aliases: ['auth', 'authentication', 'sso', 'firebase'],
  },
  googleOAuth: {
    clientId: process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID,
    clientSecret: process.env.GOOGLE_OAUTH_SECRET,
    redirectPath: '/api/auth/callback/google',
  },
  strapi: {
    hostname: process.env.STRAPI_HOSTNAME || 'strapi.aventure.vc',
    url: process.env.NEXT_PUBLIC_STRAPI_URL || 'https://strapi.aventure.vc',
    aliases: ['cms', 'blog', 'services'],
  },
  // Sentry error tracking configuration
  sentry: {
    organization: process.env.SENTRY_ORGANIZATION || 'aventure',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    enabled: process.env.ENABLE_SENTRY === 'true',
    project: process.env.SENTRY_PROJECT || 'front-end',
  },
  // Environment-specific configurations
  // Sandbox environment
  sandbox: {
    url: 'https://sandbox.aventure.vc',
    apiUrl: 'https://sandbox.api.aventure.vc/api',
    apiImageHostName: 'https://images.aventure.vc',
    apiNewsImageHostName: 'https://images.aventure.vc/images/news/',
    apiHostName: 'sandbox.api.aventure.vc',
    googleCloudProject: 'sandbox-417216',
    googleCloudServiceAccount: 'sandbox-417216@appspot.gserviceaccount.com',
    aliases: ['sandbox'],
  },
  // Development environment
  development: {
    url: 'https://dev.aventure.vc',
    apiUrl: 'https://dev.api.aventure.vc/api',
    apiImageHostName: 'https://images.aventure.vc',
    apiNewsImageHostName: 'https://images.aventure.vc/images/news/',
    apiHostName: 'dev.api.aventure.vc',
    googleCloudProject: 'development-417216',
    googleCloudServiceAccount: 'development-417216@appspot.gserviceaccount.com',
    aliases: ['dev', 'development'],
  },
  // Staging environment
  staging: {
    url: 'https://staging.aventure.vc',
    apiUrl: 'https://api.aventure.vc/api',
    apiImageHostName: 'https://images.aventure.vc',
    apiNewsImageHostName: 'https://images.aventure.vc/images/news/',
    apiHostName: 'api.aventure.vc',
    googleCloudProject: 'staging-417216',
    googleCloudServiceAccount:
      'staging-service-account@staging-417216.iam.gserviceaccount.com',
    aliases: ['qa', 'quality-assurance', 'staging', 'stage'],
  },
  // Production environment
  production: {
    url: 'https://prod.aventure.vc',
    apiUrl: 'https://api.aventure.vc/api',
    apiImageHostName: 'https://images.aventure.vc',
    apiNewsImageHostName: 'https://images.aventure.vc/images/news/',
    apiHostName: 'api.aventure.vc',
    googleCloudProject: 'production-417216',
    googleCloudServiceAccount: 'production-417216@appspot.gserviceaccount.com',
    aliases: ['prod', 'production', 'main', 'master'],
  },
  // Local development environment
  local: {
    url: 'http://localhost:3000',
    apiUrl: 'https://dev.api.aventure.vc/api',
    apiImageHostName: 'https://images.aventure.vc',
    apiNewsImageHostName: 'https://images.aventure.vc/images/news/',
    apiHostName: 'dev.api.aventure.vc',
    googleCloudProject: 'sandbox-417216',
    googleCloudServiceAccount: 'sandbox-417216@appspot.gserviceaccount.com',
    aliases: ['local'],
  },
};

// Extract values based on the current environment
const url = process.env.NEXT_PUBLIC_URL;
const apiUrl = process.env.NEXT_PUBLIC_API_URL;
const apiImageHostName = process.env.NEXT_PUBLIC_IMAGES_BUCKET;
const apiNewsImageHostName = process.env.NEXT_PUBLIC_NEWS_IMAGES_PATH;
const strapiHostUrl = process.env.NEXT_PUBLIC_STRAPI_URL;
const strapi = {
  apiToken: process.env.STRAPI_API_TOKEN,
};

// Helper functions for redirect URLs
const getRedirectUrl = (baseUrl, path) => {
  const trimmedUrl = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
  return `${trimmedUrl}${path}`;
};

const getRedirectUrls = () => {
  const baseUrl = ENVIRONMENT_CONFIGS[currentEnv]?.url;
  if (!baseUrl) {
    console.error(`No URL configured for environment: ${currentEnv}`);
    return { googleRedirectUrl: '', linkedInRedirectUrl: '' };
  }
  const googleRedirectUrl = getRedirectUrl(
    baseUrl,
    ENVIRONMENT_CONFIGS.googleOAuth.redirectPath
  );
  const linkedInRedirectUrl = getRedirectUrl(
    baseUrl,
    ENVIRONMENT_CONFIGS.firebase.linkedInAuth.redirectPath
  );

  return {
    googleRedirectUrl,
    linkedInRedirectUrl,
  };
};


// Export the configuration objects
export {
  ENVIRONMENT_CONFIGS,
  apiUrl,
  url,
  apiImageHostName,
  strapiHostUrl,
  strapi,
  currentEnv,
  apiNewsImageHostName,
  getRedirectUrl,
  getRedirectUrls,
};

// Assign the default export to a variable before exporting
const defaultConfig = {
  ENVIRONMENT_CONFIGS,
  apiUrl,
  url,
  apiImageHostName,
  strapiHostUrl,
  strapi,
  currentEnv,
  apiNewsImageHostName,
  getRedirectUrl,
  getRedirectUrls,
};

export default defaultConfig;
