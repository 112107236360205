// src/components/research/components/runningImageString/RunningImageHandler.jsx
// This file uses ES6 modules (ESM)

import DefaultImage from "@/components/ui/DefaultImage";
import { imgSelector } from "@/utils/imgSelector";
import { Box } from "@chakra-ui/react";
import Link from "next/link";
import { useState } from "react";

const className =
  "w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_50px,_black_calc(100%-50px),transparent_100%)]";

export const RunningImageHandler = ({ runDir = "right", data }) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  return (
    <>
      {data && (
        <Box
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className={className}
        >
          <RenderList
            direction={runDir}
            data={data}
            ariaHidden={false}
            isMouseOver={isMouseOver}
          />
          <RenderList
            direction={runDir}
            data={data}
            ariaHidden={true}
            isMouseOver={isMouseOver}
          />
        </Box>
      )}
    </>
  );
};

function RenderList({ direction, data, ariaHidden, isMouseOver }) {
  return (
    <ul
      className={`flex items-center justify-center md:justify-start [&_li]:mx-[10px] [&_img]:max-w-none
            ${
              direction === "right"
                ? "animate-scrollRight"
                : "animate-scrollLeft"
            } ${isMouseOver ? "paused" : "run"} `}
      aria-hidden={ariaHidden}
    >
      {data.map((item, index) => {
        return (
          <Link
            key={"runString" + item?.id + "—" + index}
            href={`/companies/${item?.slug}`}
            alt={item?.nameBrand}
          >
            <li className="w-[160px] h-[101px]  rounded-[10px] bg-white flex items-center justify-center cursor-pointer">
              <DefaultImage
                src={imgSelector({ data: item })}
                options={{
                  alt: item?.nameBrand,
                  width: "80px",
                  height: "80px",
                  style: { objectFit: "scale-down" },
                }}
              />
            </li>
          </Link>
        );
      })}
    </ul>
  );
}
